export enum StateModeEnum {
    undefined = 0,

    insert = 1,
    edit = 2,
    remove = 3,
    browse = 4,
    selected = 5,
    modified = 6,
}

export enum GridNameEnum {
    OTKWEB_FORNECEDOR_CONTATO = 'OTKWEB_FORNECEDOR_CONTATO',
    OTKWEB_FORNECEDOR_PESQUISAR = 'OTKWEB_FORNECEDOR_PESQUISAR',
    OTKWEB_COTACAO_PUBLICA_V1 = 'OTKWEB_COTACAO_PUBLICA_V1',
    OTKWEB_PEDIDO = 'OTKWEB_PEDIDO',
    OTKWEB_PEDIDO_PRODUTOS = 'OTKWEB_PEDIDO',
    OTKWEB_COTACAO_V1 = 'OTKWEB_COTACAO_V1',
    OTKWEB_COTACAO_PRODUTOS = 'OTKWEB_COTACAO_PRODUTOS',
}
